import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Nos services | Avis Découvrez la location de voitures
			</title>
			<meta name={"description"} content={"L'élégance, la puissance - Votre voyage vous attend avec Avis\n"} />
			<meta property={"og:title"} content={"Nos services | Avis Découvrez la location de voitures"} />
			<meta property={"og:description"} content={"L'élégance, la puissance - Votre voyage vous attend avec Avis\n"} />
			<meta property={"og:image"} content={"https://bordeauxblis.com/media/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://bordeauxblis.com/media/3453452236623.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://bordeauxblis.com/media/3453452236623.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://bordeauxblis.com/media/3453452236623.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://bordeauxblis.com/media/3453452236623.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://bordeauxblis.com/media/3453452236623.png"} />
			<meta name={"msapplication-TileImage"} content={"https://bordeauxblis.com/media/3453452236623.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Points forts du service
				</Text>
				<Text font="--lead" color="--darkL2" padding="0px 0px 50px 0px">
					Chez Avis, nous proposons une expérience inégalée à ceux qui recherchent le summum du luxe automobile. Nos services sont méticuleusement conçus pour vous immerger dans le monde des voitures exotiques et de prestige, où chaque trajet est une symphonie de puissance, de performance et d'opulence pure.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Flotte de voitures exotiques
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							- Supercars : Vivez l'expérience d'une vie avec notre collection de supercars à la pointe de la technologie, dotées d'une vitesse et d'un style inégalés.
							<br />
							<br />
							- Berlines de luxe : laissez-vous séduire par la sophistication de nos berlines de luxe, où le raffinement côtoie la route.
							<br />
							<br />
							- SUV royaux : Prenez le contrôle de la route avec notre gamme de SUV luxueux, alliant puissance, style et polyvalence pour une aventure de conduite ultime.
						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://bordeauxblis.com/media/6.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://bordeauxblis.com/media/7.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Forfaits sur mesure
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							- Services de chauffeur : Nos chauffeurs professionnels vous garantissent une expérience de voyage luxueuse et sans faille.
							<br />
							<br />
							- Itinéraires personnalisés : Personnalisez votre aventure en voiture grâce à nos itinéraires sur mesure, qui proposent des routes pittoresques et des arrêts exclusifs.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Luxe à court terme
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							- Location à la journée : Pour ceux qui ont envie de goûter au luxe, nos locations à la journée sont l'occasion idéale de découvrir ces voitures exceptionnelles.
							<br />
							<br />
							- Escapades de fin de semaine : Faites de vos week-ends des moments inoubliables grâce à une voiture de prestige.
						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://bordeauxblis.com/media/8.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://bordeauxblis.com/media/9.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
						max-height="400px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Des normes de service exceptionnelles
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							- Un service de conciergerie : Notre service de conciergerie veille à ce que chaque détail de votre expérience soit traité avec le plus grand soin et la plus grande attention.
							<br />
							<br />
							- Livraison par voiturier : Faites livrer la voiture de votre choix à votre porte, éliminant ainsi tout problème lié à la location d'une voiture de luxe.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-primary">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="45%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Mesures d'acceptation des véhicules de prêt
				</Text>
			</Box>
			<Box
				width="55%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
					Afin de préserver l'exclusivité et l'état impeccable de notre flotte, certaines mesures sont mises en place lors du choix et de l'acceptation des véhicules de prêt. Notre équipe veille à ce que chaque conducteur comprenne et respecte la responsabilité de piloter ces véhicules haut de gamme.
					<br />
					<br />
					Pour plus de détails ou pour vous lancer dans une expérience de conduite passionnante, contactez-nous. Notre équipe s'engage à ce que vous trouviez la voiture de luxe idéale qui corresponde à votre style et à vos préférences.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});